import { Block, Flexbox, Text, useTheme } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type ButtonPlayProps = FlexboxTypes.Props

const ButtonPlay = (props: ButtonPlayProps) => {
  const { ...restProps } = props
  const theme = useTheme()
  return (
    <Flexbox
      alignItems="center"
      {...restProps}
      style={{
        ' article': {
          transition: 'all 0.25s',
        },
        ':hover': {
          cursor: 'pointer',
        },
        ':hover > article': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Block mr="1.125rem" h="0.125rem" w="2.25rem" backgroundColor="red800" />
      <Text color="red800" mr="1.125rem">
        ОНЛАЙН ДЕМО
      </Text>
      <Flexbox
        as="article"
        centered
        mr="1.125rem"
        h="4rem"
        w="4rem"
        borderRadius="100%"
        borderStyle="solid"
        borderColor="red800"
        borderWidth="0.125rem"
      >
        <Block
          w={0}
          h={0}
          mr="-0.25rem"
          borderWidth="0.75rem 0 0.75rem 1.25rem"
          borderStyle="solid"
          style={{
            borderColor: `transparent transparent transparent ${theme.color.green[600].hex()}`,
          }}
        />
      </Flexbox>
    </Flexbox>
  )
}

export default ButtonPlay
