import React from 'react'

import ScreenOrders from '@assets/images/screen_orders.jpg'
import UIMenuImage from '@assets/images/ui_console_menu.png'
import UIConsoleOrderInfo from '@assets/images/ui_console_orderinfo.png'
import MacBook from '@src/components/MacBook'
import { Block, Flexbox, Paragraph } from '@stage-ui/core'

import ButtonAppMarket from '@src/components/ButtonAppMarket'
import ButtonPlay from '@src/components/ButtonPlay'
import Cube from '@src/components/Cube'

import { upDownAnim1, upDownAnim2 } from '@utils/keyframes'

import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

import OrbitBackground from '@src/components/OrbitBackground'
import { metrikaGoal } from '@src/utils/metrikaGoal'

const S01Header = (props: FlexboxTypes.Props) => {
  return (
    <>
      <OrbitBackground orbits top={['-30rem', '-35rem']} left={['-20rem', '-30rem']} />
      <Flexbox column h={['calc(100vh - 18rem)', 'auto']} w="36rem" {...props}>
        <Paragraph
          size={['3.25rem', '2.25rem']}
          lineHeight={['120%', '120%']}
          weight={700}
          color="black"
        >
          Автоматизация <br />
          бизнеса для <br />
          поставщиков <br />
          HoReCa
        </Paragraph>
        <Paragraph mt="1.5rem" size="1.125rem" color="gray600">
          Рост доступный каждому
        </Paragraph>
        <a
          href="#videoPlayback"
          style={{ textDecoration: 'none' }}
          onClick={() => {
            metrikaGoal('clickHeaderVideoPlay')
          }}
        >
          <ButtonPlay mt="2.5rem" />
        </a>
        <Flexbox alignItems="center" mt="3.5rem">
          <ButtonAppMarket
            type="google"
            mr="m"
            onClick={() => {
              metrikaGoal('clickAppDownloadGoogle')
            }}
          />
          <ButtonAppMarket
            type="apple"
            onClick={() => {
              metrikaGoal('clickAppDownloadApple')
            }}
          />
        </Flexbox>
      </Flexbox>
      <MacBook
        display={['flex', 'none']}
        screen={ScreenOrders}
        style={{
          position: 'absolute',
          right: '-10rem',
          top: '6rem',
        }}
      >
        <Cube
          hue={0}
          reverse
          style={{
            position: 'absolute',
            right: '38%',
            top: '-10%',
          }}
        />
        <Cube
          reverse
          scale={0.8}
          hue={190}
          style={{
            position: 'absolute',
            right: '5%',
            top: '60%',
          }}
        />
        <Cube
          scale={1.5}
          blur={5}
          hue={350}
          reverse
          duration={30}
          style={{
            opacity: 0.8,
            position: 'absolute',
            right: '-10%',
            top: '30%',
            zIndex: 2,
          }}
        />
        <Block
          shadow="xl"
          borderRadius="0.5rem"
          style={[
            {
              position: 'absolute',
              left: '1.5rem',
              top: '-2rem',
              width: '11.5rem',
              height: '24.8125rem',
              background: `url(${UIMenuImage}) no-repeat`,
              backgroundSize: 'cover',
              animation: `${upDownAnim1} 8s ease-in-out infinite`,
              zIndex: 1,
              backdropFilter: 'blur(8px)',
            },
          ]}
        />
        <Block
          shadow="xl"
          borderRadius="0.5rem"
          style={[
            {
              position: 'absolute',
              right: '1.5rem',
              top: '-2rem',
              width: '15rem',
              height: '9rem',
              background: `url(${UIConsoleOrderInfo}) no-repeat`,
              backgroundSize: 'cover',
              animation: `${upDownAnim2} 8s ease-in-out infinite`,
              zIndex: 1,
              backdropFilter: 'blur(8px)',
            },
          ]}
        />
      </MacBook>
    </>
  )
}

export default S01Header
